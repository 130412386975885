import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-6" }
const _hoisted_3 = { class: "col-xxl-6" }
const _hoisted_4 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MixedWidget7 = _resolveComponent("MixedWidget7")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoadingOrdersReport == false)
        ? (_openBlock(), _createBlock(_component_MixedWidget7, {
            key: 0,
            "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
            "chart-color": "primary",
            "chart-height": "150",
            title: "Orders Report",
            description: `Food Delivery: ${_ctx.startDate} - ${_ctx.endDate}`,
            result: `${_ctx.countFoodOrder} ORDERS`
          }, null, 8, ["description", "result"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isLoadingOrdersReport == false)
        ? (_openBlock(), _createBlock(_component_MixedWidget7, {
            key: 0,
            "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
            "chart-color": "primary",
            "chart-height": "150",
            title: "Orders Report",
            description: `Messenger: ${_ctx.startDate} - ${_ctx.endDate}`,
            result: `${_ctx.countMessengerOrder} ORDERS`
          }, null, 8, ["description", "result"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isLoadingPromotionReport == false)
        ? (_openBlock(), _createBlock(_component_MixedWidget7, {
            key: 0,
            "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
            "chart-color": "green",
            "chart-height": "150",
            title: "Promotion Report",
            description: `Food Delivery: ${_ctx.startDate} - ${_ctx.endDate}`,
            result: `฿${_ctx.salePromotionSummary}`
          }, null, 8, ["description", "result"]))
        : _createCommentVNode("", true)
    ])
  ]))
}